<template>
  <div>
    <div class="mb-7">
      <div class="row align-items-center" v-if="$t('clan')=='en'">
        <div class="col-lg-9 col-xl-8">
          <div class="row align-items-center">
            
            <div class="col-md-6 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Status:</label>
                <div class="dropdown bootstrap-select col-md-11 ">
                  <select
                    class="form-control"
                    id="kt_datatable_search_status"
                    tabindex="null"
                    v-model="selected"
                    @change="setSearch"
                    
                  >
                  
                    <option :value="state.name" v-for="state in status" :key="state.id">{{state.name}}</option>
                    </select
                  >
                  
                </div>
              </div>
            </div>
            <div class="col-md-6 my-2 my-md-0">
              <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">Date:</label>
                <b-form-input
                            type="date"
                            class="form-control  form-control-md"
                            name="state"
                            placeholder="Date"
                            v-model="date"
                            @change="setSearch"
                            
                          />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0">
          <a href="#" class="btn btn-light-primary px-6 font-weight-bold"
            >Search</a
          >
        </div>
      </div>
      <div class="row align-items-center"  v-else>
        <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0" >
          <a href="#" class="btn btn-light-primary px-6 font-weight-bold "
          style="margin-left:300px"
            >{{$t('search')}}</a
          >
        </div>
        <div class="col-lg-9 col-xl-8" >
          <div class="row align-items-center">
            <div class="col-md-6 my-2 my-md-0">
              <div class="d-flex align-items-center">
                  
                <b-form-input
                            type="date"
                            class="form-control  form-control-md mr-5"
                            name="state"
                            placeholder="Date"
                            v-model="date"
                            @change="setSearch"
                            
                          />
                          <label class="mr-3 mb-0 d-none d-md-block">{{$t('date')}}</label>
              </div>
            </div>
            
            <div class="col-md-6 my-2 my-md-0">
              <div class="d-flex align-items-center">
                
                <div class="dropdown bootstrap-select col-md-11 ">
                  <select
                    class="form-control"
                    id="kt_datatable_search_status"
                    tabindex="null"
                    v-model="selected"
                    @change="setSearch"
                    
                  >
                  
                    <option :value="state.name" v-for="state in status" :key="state.id">{{state.name}}</option>
                    </select
                  >
                  
                </div>
                <label class="mr-3 mb-0 d-none d-md-block">{{$t('status')}}</label>
              </div>
            </div>
            
          </div>
        </div>
        
      </div>
      
    </div>

    <b-table
      :items="items"
      :fields="$t('clan')=='en'?table.fields:table2.fields"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      v-if="(selected==='All' ) && date===''"
    >
      <template #cell(actions)="row">
        <div class="">
          <button @click.prevent="viewOrder(row.item.id)" class="">
            <i class="far fas fa-eye"></i>
            {{$t('view')}}
          </button>
          <span v-if="row.item.status !=='Cancelled'">
          <button @click.prevent="showModal(row.item.id)" class="">
            <i :class="$t('clan')=='en'?'fas fa-trash ml-7':'fas fa-trash ml-3'"></i>
            {{$t('cancel')}}
          </button>
          </span>
        </div>
        <div>
          <button @click.prevent="viePickup(row.item.id)" class="">
            <i class="far fas fa-dolly mt-5"></i>
            {{$t('pickup')}}
          </button>
          <button @click.prevent="viewDelivery(row.item.id)" class="">
            <i class="far fas fa-truck ml-3 mt-5"></i>
            {{$t('delivery')}}
          </button>
        </div>
      </template>

      <template #cell(created_at)="row">
        <p>{{ row.item.created_at.split(" ")[0] }}</p>
      </template>
       <template #cell(zone_id)="row">
          <template v-for="zone in row.item.client.zones" >
            <span v-if="zone.id==row.item.zone_id" :key="zone.id" >
              {{zone.name_ar}}              
            </span>
          </template>
       </template>

      <template #cell(status)="row">
        <span
          class="label font-weight-bold label-lg label-light-secondary text-dark label-inline"
          v-if="row.item.status === 'Created'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-primary label-inline"
          v-if="row.item.status === 'Started'"
          >{{ $t(row.item.status) }}</span
        >
         <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Failed'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Cancelled'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-success label-inline"
          v-if="row.item.status === 'Successful'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-warning label-inline"
          style="width:70px"
          v-if="row.item.status === 'Unassigned'"
          
          >{{ $t(row.item.status) }}</span
        >
      </template>

      <template #cell(isAddressConfirmed)="row">
        <div v-if="row.item.isAddressConfirmed == '1'" >
          <b-badge variant="primary" style="font-size:10px">{{$t('Yes')}}</b-badge>
        </div>
        <div v-else>
          {{$t('No')}}
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <b-table
      :items="searchedItem"
      :fields="$t('clan')=='en'?table.fields:table2.fields"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      v-else
    >
      <template #cell(actions)="row">
        <div class="">
          <button @click.prevent="viewOrder(row.item.id)" class="">
            <i class="far fas fa-eye"></i>
            {{$t('view')}}
          </button>
          <span v-if="row.item.status !=='Cancelled'">
          <button @click.prevent="showModal(row.item.id)" class="">
            <i :class="$t('clan')=='en'?'fas fa-trash ml-7':'fas fa-trash ml-3'"></i>
            {{$t('cancel')}}
          </button>
          </span>
        </div>
        <div>
          <button @click.prevent="viePickup(row.item.id)" class="">
            <i class="far fas fa-dolly mt-5"></i>
            {{$t('pickup')}}
          </button>
          <button @click.prevent="viewDelivery(row.item.id)" class="">
            <i class="far fas fa-truck ml-3 mt-5"></i>
            {{$t('delivery')}}
          </button>
        </div>
      </template>

      <template #cell(created_at)="row">
        <p>{{ row.item.created_at.split(" ")[0] }}</p>
      </template>

      <template #cell(zone_id)="row">
          <template v-for="zone in row.item.client.zones" >
            <span v-if="zone.id==row.item.zone_id" :key="zone.id" >
              {{zone.name_ar}}              
            </span>
          </template>


      </template>

      <template #cell(status)="row">
        <span
          class="label font-weight-bold label-lg label-light-secondary text-dark label-inline"
          v-if="row.item.status === 'Created'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-primary label-inline"
          v-if="row.item.status === 'Started'"
          >{{ $t(row.item.status) }}</span
        >
         <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Failed'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Cancelled'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-success label-inline"
          v-if="row.item.status === 'Successful'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-warning label-inline"
          style="width:70px"
          v-if="row.item.status === 'Unassigned'"
          
          >{{ $t(row.item.status) }}</span
        >
      </template>

      <template #cell(isAddressConfirmed)="row">
        <div v-if="row.item.isAddressConfirmed === '1'" >
          <b-badge variant="success" style="font-size:10px">{{$t('Yes')}}</b-badge>
        </div>
        <div v-else>
          {{$t('No')}}
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <!--<b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="left"
      size="lg"
      class="my-0"
      @click.native="getData"
    ></b-pagination>-->

    <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>



    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="$t('Order Cancelation')"
      hide-footer
    >
      <p>
        {{$t("OD")}}
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >{{$t('No')}}</b-button
        >
        <b-button variant="danger" @click="cancelOrder" >{{$t('Yes')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters,mapState } from "vuex";
import Swal from "sweetalert2";


export default {
  name: "OrdersTable",
  data: function () {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      user: "",
      items: [],
      selected:'All',
      date:'',
      status:[
        {id:1, name:"All"},
          {id:2, name:"Created"},
          {id:3, name:"Started"},
          {id:4, name:"Failed"},
          {id:5, name:"Successful"},
          {id:6, name:"Cancelled"},
      ],
      searchedItem:'',
      orderId:'',
      data:'',
      firstPage:1,
      lastPage:''
      
      
    };
  },
  created(){
    this.data = JSON.parse(localStorage.getItem('client'))
    
  },
  async mounted() {
    
    //console.log(this.data)
    if(this.data !==null){

   
    var cre = {
      client_id : this.data.id,
      from:"2020-1-1",
      to:'2030-1-1',
      status : 0
    }
    this.$store.dispatch('orders/orderHistory', cre).then((res)=>{
      //console.log(this.orders)
      this.items = this.orders
      this.totalRows = res.total
      this.lastPage = res.last_page
    })
     }

    
    

    /*this.$store.dispatch("clients/loadAll");
    this.user = await this.$store.dispatch(VERIFY_AUTH);
    
   
    var client_id = this.clients.items.filter(
      (s) => s.name === this.user.data.name
    );
   var lastPage = 0

    await this.$store.dispatch('orders/loadAll').then(res=>{
      //this.totalRows = res.data.total
      //console.log(res.data.last_page)
      if(res.data.next_page_url !==null){
        //console.log("done")
        lastPage = res.data.last_page
      }
    })

    var total = null
    var x = []
        for (var s = 1 ; s<=lastPage ; s++){
          
           await this.$store.dispatch('orders/loadPage',s).then((res)=>{
             //console.log(res)
             x = x.concat(res.data.data)
             
         var d = res.data.data.filter(x=>x.client_id == client_id[0].id)
         total = total + d.length
         this.totalRows = total
         
      })
    }
    */
    
    //var orde = [];
    //orde = x.filter((x) => x.client_id == client_id[0].id.toString());
    //this.items = orde;
    
  },
  computed: {
    order() {
      return (
        this.orders
      );
    },
    ...mapState({
      orders: (state) => state.orders.orders,
    }),
    ...mapGetters({ table: "orders/table2" }),
    ...mapGetters({ table2: "orders/table2AR" }),

  },

  methods: {
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async cancelOrder(){
      let order ={
        id:this.orderId
      }
      await this.$store.dispatch('orders/cancelTask',order).then(()=>{

        var cre = {
      client_id : this.data.id,
      from:"2020-1-1",
      to:'2030-1-1',
      status : 0
    }
    this.$store.dispatch('orders/orderHistory', cre).then((res)=>{
      this.items = this.orders
      this.totalRows = res.total
    })
        
        this.hideModal()
        
      })
      Swal.fire({
          title: "",
          text: "This order has been cancelled successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      

    },
    showModal(id) {
      this.$refs["my-modal"].show();
      this.orderId = id
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    async getData(){
      //console.log("Next Page")
      
      var cre={
        client_id : this.data.id,
      from:"2020-1-1",
      to:'2030-1-1',
      status : 0,
      pageNumber:this.currentPage
      }
      await this.$store.dispatch('orders/loadPageC',cre).then(()=>{
        this.items = this.orders
     
         
      })
      
   
      //console.log(orde)
      
      
      

    },
      setSearch(){
         
          if(this.selected !=='All' && this.date === '')
          {
              this.searchedItem = this.items.filter(s=>s.status === this.selected)
              
          }
          else if(this.selected !=='All' && this.date !== null)
          {
              this.searchedItem = this.items.filter(s=>s.status === this.selected && s.created_at.split(" ")[0] == this.date)
              
          }
          else if(this.selected === 'All' && this.date !== null)
          {
              this.searchedItem = this.items.filter(s=> s.created_at.split(" ")[0] === this.date)
              
          }
          
         

          

      },
    viewOrder(id) {
      this.$router.push({ name: "ViewRequest", params: { id: id } });
    },
    viePickup(id){
      this.$router.push({ name: "ViewPickup", params: { id: id } });

    },
    viewDelivery(id){
      this.$router.push({ name: "ViewDelivery", params: { id: id } });

    }
  },
};
</script>
<style lang="css" scoped>
</style>