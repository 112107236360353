<template>
  <div>
    <div class="rows">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$t('Request History')}}</h3>
            </div>
            <span class="add"> </span>
          </div>
          <div class="card-body">
            <div>
              <RequestTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RequestTable from "@/view/pages/DriverPanel/RequestHistory/RequestTable.vue";

export default {
  name: "Orders",

  components: { RequestTable },

  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Request History", route: "/Clients/Requests-History" },
    ]);
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
@media only screen and (max-width: 600px) {
  .col-xl-6,
  .col-md-12 {
    position: relative;
    width: 110%;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: -20px;
  }
}
</style>